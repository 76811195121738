import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

//Styled Components
import { Container } from "../../styles/globalStyles";

//Context
import { useGlobalStateContext } from "../../context/globalContext";

import genshin from "../../assets/images/genshin.png";

const StyledHomeProjects = styled.div`
    background-color: #fff;
    scroll-margin-top: -50px;
`;

const Content = styled(motion.div)`
    margin-left: 140px;
    padding-top: 200px;

    h2 {
        color: ${(props) => props.theme.colorPrimary};
        font-size: 4.6rem;
        font-weight: 600;
    }
    h3 {
        padding-top: 20px;
        color: ${(props) => props.theme.subtext};
        font-size: 3.2rem;
        font-weight: 600;
    }
`;

const Projects = styled(motion.div)`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 80px;
`;

const ProjectTile = styled(motion.a)`
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.subtext};
    img {
        width: 100%;
        /* border: 1px dashed ${(props) => props.theme.colorPrimary}; */
    }
    p {
        font-size: 1.6rem;
        text-align: center;
        margin: 10px 0;
    }
`;

const HomeProjects = () => {
    const [projectsRef, inView] = useInView({ rootMargin: "-300px" });

    return (
        <StyledHomeProjects ref={projectsRef} id="projects">
            <motion.div
                initial={{ opacity: 0, y: 72, scale: 0.95 }}
                animate={
                    inView && {
                        opacity: 1,
                        y: 0,
                        scale: 1,
                        transition: {
                            duration: 0.6,
                            ease: [0.6, 0.05, -0.01, 0.9],
                        },
                    }
                }
            >
                <Container>
                    <Content>
                        <motion.h2
                            initial={{ opacity: 0, scale: 1.02 }}
                            animate={
                                inView && {
                                    opacity: 1,
                                    scale: 1,
                                    transition: { duration: 1 },
                                }
                            }
                        >
                            Projects
                        </motion.h2>
                    </Content>
                    <Projects>
                        <ProjectTile
                            href="https://genshin.thekima.com"
                            target="_blank"
                            rel="noreferrer noopener"
                            whileHover={{
                                scale: 1.05,
                                y: -20,
                                boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.6)",
                                transition: {
                                    ease: "circOut",
                                    duration: 0.6,
                                },
                                color: "#00b4d8",
                                borderRadius: "7px",
                            }}
                        >
                            <motion.img
                                src={genshin}
                                initial={{ opacity: 0, y: 72, x: 50 }}
                                animate={
                                    inView && {
                                        opacity: 1,
                                        y: 0,
                                        x: 0,
                                        transition: {
                                            duration: 0.6,
                                            delay: 0.5,
                                            ease: [0.6, 0.05, -0.01, 0.9],
                                        },
                                    }
                                }
                            />
                            <p>Genshin Wish Simulator</p>
                        </ProjectTile>
                    </Projects>
                </Container>
            </motion.div>
        </StyledHomeProjects>
    );
};

export default HomeProjects;
