import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

//Styled Components
import { Container } from "../../styles/globalStyles";

const StyledHomeContent = styled.div`
    padding-bottom: 100px;
    background-color: #fff;
`;

const Content = styled(motion.div)`
    margin-left: 140px;
    padding-top: 200px;
    h2 {
        color: ${(props) => props.theme.colorPrimary};
        font-size: 4.6rem;
        font-weight: 600;
    }
    h3 {
        padding-top: 20px;
        color: ${(props) => props.theme.subtext};
        font-size: 3.2rem;
        font-weight: 600;
    }
`;

const HomeContent = () => {
    const [contentRef, inView] = useInView({ rootMargin: "-300px" });

    return (
        <StyledHomeContent ref={contentRef}>
            <Container>
                <Content>
                    <motion.h2
                        initial={{ opacity: 0, scale: 1.02 }}
                        animate={
                            inView && {
                                opacity: 1,
                                scale: 1,
                                transition: { duration: 1 },
                            }
                        }
                    >
                        I'll fully design your website.
                    </motion.h2>
                    <motion.h3
                        initial={{ opacity: 0, y: 72 }}
                        animate={
                            inView && {
                                opacity: 1,
                                y: 0,
                                transition: {
                                    duration: 0.6,
                                    ease: [0.6, 0.05, -0.01, 0.9],
                                },
                            }
                        }
                    >
                        To stand out.
                        <br />
                        It's all about the catch.
                    </motion.h3>
                </Content>
            </Container>
        </StyledHomeContent>
    );
};

export default HomeContent;
