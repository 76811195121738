import React from "react";
import heroBg from "../../assets/images/heroBg.jpg";
import { motion } from "framer-motion";

//Styled components
import styled from "styled-components";
import { Container, Flex } from "../../styles/globalStyles";

const StyledHomeHero = styled.div`
    height: 110vh;
    width: 100%;
`;

const HeroBackground = styled(motion.div)`
    position: fixed;
    right: 0;
    left: 20%;
    top: 120px;
    z-index: -1;
    overflow: hidden;
    img {
        width: 100%;
        min-height: 100%;
    }
`;

const HeroTitle = styled(motion.h2)`
    position: absolute;
    bottom: -110px;
    left: -4px;
    padding-bottom: 30px;
    overflow: hidden;
    pointer-events: none;
`;

const Headline = styled(motion.span)`
    display: block;
    font-size: 18rem;
    font-weight: 900;
    line-height: 0.76;
    color: ${(props) => props.theme.text};
`;

const HomeHero = () => {
    const parentVar = {
        hidden: { y: 120 },
        show: {
            y: 0,
            transition: {
                duration: 0.8,
                ease: [0.6, 0.05, -0.01, 0.9],
                staggerChildren: 0.3,
            },
        },
    };
    const childVar = {
        hidden: { y: 120 },
        show: {
            y: 0,
            transition: { duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] },
        },
    };

    return (
        <StyledHomeHero>
            <HeroBackground
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                    duration: 0.7,
                }}
            >
                <motion.img
                    initial={{ x: 110, y: 20, scale: 1.6 }}
                    animate={{ x: -110, y: -20 }}
                    transition={{
                        duration: 14,
                        ease: "easeInOut",
                        // yoyo: Infinity,
                    }}
                    src={heroBg}
                ></motion.img>
            </HeroBackground>
            <HeroTitle initial="hidden" animate="show" variants={parentVar}>
                <Headline variants={childVar}>DESIGN+</Headline>
                <Headline variants={childVar}>CONQUER</Headline>
            </HeroTitle>
        </StyledHomeHero>
    );
};

export default HomeHero;
