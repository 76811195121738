import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//Pages
import Home from "./pages/home";

//Styled Components
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { normalize } from "styled-normalize";

//Components
import Navigation from "./components/layout/Navigation";
import Footer from "./components/layout/Footer";

//Context
import {
    useGlobalDispatchContext,
    useGlobalStateContext,
} from "./context/globalContext";
import CustomCursor from "./components/CustomCursor";

const GlobalStyle = createGlobalStyle`
${normalize}
* {
  text-decoration: none;
  margin: 0;
  padding: 0;
  /* cursor: none; */
}
html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 62.5%;
    scroll-behavior: smooth;
}
body {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: ${(props) => props.theme.background};
    overscroll-behavior: none;
    overflow-x: hidden;
}
`;

function App() {
    const [toggleMenu, setToggleMenu] = useState(false);
    const darkTheme = {};
    const lightTheme = {
        text: "#000",
        subtext: "#4a4a4a",
        background: "#fff",
        colorPrimary: "#00b4d8",
        ease: [0.6, 0.05, -0.01, 0.9],
    };

    const { currentTheme, cursorStyles, cursorType } = useGlobalStateContext();
    const dispatch = useGlobalDispatchContext();

    const onCursor = (cursorType) => {
        cursorType = (cursorStyles.includes(cursorType) && cursorType) || false;
        dispatch({ type: "CURSOR_TYPE", cursorType });
    };

    return (
        <ThemeProvider theme={lightTheme}>
            <GlobalStyle />
            <CustomCursor />
            <Router>
                <Navigation
                    toggleMenu={toggleMenu}
                    setToggleMenu={setToggleMenu}
                    onCursor={onCursor}
                />
                <Switch>
                    <Route exact path="/" component={Home} />
                </Switch>
                <Footer />
            </Router>
        </ThemeProvider>
    );
}

export default App;
