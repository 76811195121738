import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

//Styled Components
import { Container, Flex } from "../../styles/globalStyles";

//Context
import { useGlobalStateContext } from "../../context/globalContext";

const StyledHomeAbout = styled.div`
    position: relative;
    min-height: 570px;
    background-color: ${(props) => props.theme.background};
    scroll-margin-top: 400px;
`;

const AboutContent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 350px;
    margin-left: 140px;
    font-size: 2rem;
    line-height: 1.6;
    span {
        color: ${(props) => props.theme.colorPrimary};
        font-weight: 600;
        font-size: 2.2rem;
    }
    .arrow {
        width: 120px;
        height: 80px;
        display: block;
        position: relative;
        overflow: hidden;
        svg {
            position: absolute;
            top: 16px;
            left: -48px;
            width: 108px;
            path {
                fill: ${(props) => props.theme.colorPrimary};
            }
        }
    }
`;

const AboutVideo = styled.div`
    position: absolute;
    width: 600px;
    height: 600px;
    top: 0;
    right: 0;
    display: block;
    overflow: hidden;
    pointer-events: none;
    video {
        position: absolute;
        width: 600px;
        top: 50%;
        transform: translateY(-55%);
    }
`;

const HomeAbout = () => {
    const [hovered, setHovered] = useState(false);
    const [aboutRef, inView] = useInView({ rootMargin: "-300px" });
    const { currentTheme } = useGlobalStateContext();

    return (
        <StyledHomeAbout ref={aboutRef} id="about">
            <motion.div
                initial={{ opacity: 0, y: 72, scale: 0.95 }}
                animate={
                    inView && {
                        opacity: 1,
                        y: 0,
                        scale: 1,
                        transition: {
                            duration: 0.6,
                            ease: [0.6, 0.05, -0.01, 0.9],
                        },
                    }
                }
            >
                <Container>
                    <Flex>
                        <AboutContent>
                            I go by Richard Shin or Miwoju.
                            <br />
                            Why choose me you may ask?
                            <br />
                            I'm a perfectionist.
                            <br />
                            Your site matters to me. I love UI and UX design and
                            you can expect pixel-perfect design. Expect your
                            site to stand out.
                            <br />
                            <br />
                            <Link to="/">
                                <span
                                    onMouseEnter={() => setHovered(true)}
                                    onMouseLeave={() => setHovered(false)}
                                >
                                    Presence matters.
                                    <div className="arrow">
                                        <motion.svg
                                            animate={{
                                                x: hovered ? 48 : 0,
                                                transition: {
                                                    duration: 0.6,
                                                    ease: [
                                                        0.6,
                                                        0.05,
                                                        -0.01,
                                                        0.9,
                                                    ],
                                                },
                                            }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 101 57"
                                        >
                                            <path
                                                d="M33 34H0V24h81.429L66 7.884 73.548 0l19.877 20.763.027-.029L101 28.618 73.829 57l-7.548-7.884L80.753 34H33z"
                                                fill="#FFF"
                                                fillRule="evenodd"
                                            ></path>
                                        </motion.svg>
                                    </div>
                                </span>
                            </Link>
                        </AboutContent>
                        <AboutVideo>
                            <video
                                src={require("../../assets/videos/the-catch1.mp4")}
                                autoPlay
                                loop
                                muted
                            />
                        </AboutVideo>
                    </Flex>
                </Container>
            </motion.div>
        </StyledHomeAbout>
    );
};

export default HomeAbout;
