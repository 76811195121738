import React, { Fragment } from "react";
import styled from "styled-components";

import HomeHero from "../components/homePage/HomeHero";
import HomeContent from "../components/homePage/HomeContent";
import HomeAbout from "../components/homePage/HomeAbout";

//Context
import {
    useGlobalStateContext,
    useGlobalDispatchContext,
} from "../context/globalContext";
import HomeProjects from "../components/homePage/HomeProjects";

const StyledHome = styled.div``;

const Home = () => {
    const { currentTheme, cursorStyles, cursorType } = useGlobalStateContext;
    const dispatch = useGlobalDispatchContext();

    const onCursor = (cursorType) => {
        cursorType = (cursorStyles.includes(cursorType) && cursorType) || false;
        dispatch({ type: "CURSOR_TYPE", cursorType });
    };
    return (
        <StyledHome>
            <HomeHero />
            <HomeContent />
            <HomeAbout />
            <HomeProjects />
        </StyledHome>
    );
};

export default Home;
