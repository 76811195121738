import React, { useState, useEffect } from "react";
import { Cursor } from "../styles/globalStyles";

//Context
import { useGlobalStateContext } from "../context/globalContext";

const CustomCursor = ({ toggleMenu }) => {
    const { cursorType } = useGlobalStateContext();
    const [mousePosition, setMousePosition] = useState({ y: 400, x: 400 });

    useEffect(() => {
        document.addEventListener("mousemove", onMouseMove);
        return () => {
            document.removeEventListener("mousemove", onMouseMove);
        };
    }, []);

    const onMouseMove = (event) => {
        const { pageX: x, pageY: y } = event;
        setMousePosition({ x, y });
    };

    return (
        <>
            <Cursor
                className={`${!!cursorType && "hovered"} ${cursorType} ${
                    toggleMenu && "nav-open"
                }`}
                style={{
                    top: mousePosition.y,
                    left: mousePosition.x,
                }}
            />
            {console.log(cursorType)}
        </>
    );
};

export default CustomCursor;
