import React from "react";
import { Link } from "react-router-dom";

//Styled components
import styled from "styled-components";
import { Container, Flex } from "../../styles/globalStyles";

import logoIcon from "../../assets/images/logo.png";

//Context
import {
    useGlobalStateContext,
    useGlobalDispatchContext,
} from "../../context/globalContext";

const StyledNavigation = styled.nav`
    /* height: 0; */
    /* position: absolute; */
    /* top: 72px; */
    width: 100%;
    left: 0;
    right: 0;
    position: fixed;
    padding: 68px 0 10px 0;
    background-color: #fff;
    z-index: 99;
    box-shadow: 0 1px rgba(0, 0, 0, 0.1);
`;

const Logo = styled(Link)`
    font-size: 2.4rem;
    color: ${(props) => props.theme.text};
    display: flex;
    align-items: center;
    span {
        color: ${(props) => props.theme.colorPrimary};
    }
    img {
        margin-right: 10px;
        width: 40px;
    }
`;

const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 450px;
    li {
        padding: 15px;
        list-style-type: none;
        a {
            color: ${(props) => props.theme.text};
            font-size: 1.8rem;
            &:hover {
                color: ${(props) => props.theme.colorPrimary};
            }
        }
    }
`;

const Navigation = ({ onCursor }) => {
    const NavItems = [
        { title: "About", href: "#about", target: "_self" },
        { title: "Projects", href: "#projects", target: "_self" },
        {
            title: "Genshin",
            href: "https://genshin.thekima.com/",
            target: "_blank",
        },
        { title: "Contact", href: "#contact", target: "_self" },
    ];

    return (
        <StyledNavigation>
            <Container>
                <Flex spaceBetween>
                    <Logo to="/">
                        <img src={logoIcon} />
                        The Ki<span>ma</span>
                    </Logo>
                    <NavMenu>
                        {NavItems.map(({ title, href, target }) => (
                            <li
                                onMouseEnter={() => onCursor("hovered")}
                                onMouseLeave={onCursor}
                            >
                                <a
                                    href={href}
                                    target={target}
                                    rel="noreferrer noopener"
                                >
                                    {title}
                                </a>
                            </li>
                        ))}
                    </NavMenu>
                </Flex>
            </Container>
        </StyledNavigation>
    );
};

export default Navigation;
