import React from "react";
//Styled components
import styled from "styled-components";
import { Container, Flex } from "../../styles/globalStyles";

const FooterNav = styled.nav`
    height: 300px;
    width: 100%;
    background-color: #fff;
    font-size: 1.4rem;
    a {
        color: ${(props) => props.theme.text};
    }
`;

const Socials = styled.div`
    i {
        font-size: 2.4rem;
        padding: 10px;
    }
`;

const Footer = () => {
    return (
        <FooterNav id="contact">
            <Container>
                <Flex fullHeight center column>
                    <Socials>
                        <a
                            href="https://twitter.com/miwoju7"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <i className="fab fa-twitter"></i>{" "}
                        </a>
                        <a
                            href="https://github.com/miwoju"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <i className="fab fa-github"></i>{" "}
                        </a>
                        <a
                            href="https://www.instagram.com/miwoju/"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <i className="fab fa-instagram"></i>{" "}
                        </a>
                    </Socials>
                    Email: <a href="mailto:miwoju.business@gmail.com" target="_blank">miwoju.business@gmail.com</a>
                </Flex>
            </Container>
        </FooterNav>
    );
};

export default Footer;
